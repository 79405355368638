<template>
    <div>
        <!-- login form -->
        <v-card-text>
            Success
        </v-card-text>

        <!-- create new account  -->
        <!--<v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2">
                Don't want to change?
            </span>
            <a text v-on:click="$emit('sign-in-clicked')">
                Sign in instead
            </a>
        </v-card-text>-->

    </div>
</template>

<style lang="scss">
    @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>

<script>
    export default {
        components: {
        },
        data: () => ({
            username: '',
            token: '',
            loading: false
        }),
        methods: {
            confirmEmail: async function () {
                this.loading = true;

                let model = {
                    username: this.username,
                    token: this.token
                };

                let result = await this.$axios.post(`/api/Auth/ConfirmMail`, model)
                if (result.status == 200) {
                    location.href = '/'
                }
                else {
                    alert(result.data.message)
                }

                this.loading = false;
            }
        },
        mounted: function () {
            this.token = this.$route.query.token;
            this.username = this.$route.query.username;
            this.confirmEmail();
        }
    };
</script>