import Vue from 'vue'
import VueRouter from 'vue-router'
import { routerRoutes } from './routes'
Vue.use(VueRouter)

const routes = routerRoutes.map(route => {
    return {
        ...route,
        component: () => import(`../views/${route.component}.vue`)
    }
}).concat([
    {
        path: '/reset-password', name: 'auth',
        component: () => import(`../views/home/home-screen.vue`)
    },
    {
        path: '/confirm-mail', name: 'auth',
        component: () => import(`../views/home/home-screen.vue`)
    },
    {
        path: '*', name: 'all',
        component: () => import(`../views/home/home-screen.vue`)
    }
])

let router = new VueRouter({
    mode: 'history',
    routes
})

//router.beforeEach((to, from, next) => {
//    next()
//})

export default router