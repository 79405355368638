<template>
    <v-navigation-drawer :value="isDrawerOpen"
                         app
                         floating
                         width="260"
                         class="app-navigation-menu"
                         @input="val => $emit('update:is-drawer-open', val)">
        <!-- Navigation Header -->
        <div class="vertical-nav-header d-flex align-center justify-center items-center ps-6 pe-5 pt-5 pb-2">
            <router-link to="/" class="d-flex align-center text-decoration-none">
                <v-img v-if="$vuetify.theme.dark"
                       :src="require('@/assets/images/logos/logo.png')"
                       max-height="80px"
                       max-width="80px"
                       alt="logo"
                       contain
                       eager
                       class="app-logo me-3"></v-img>
                <v-img v-else
                       :src="require('@/assets/images/logos/logo.png')"
                       max-height="80px"
                       max-width="80px"
                       alt="logo"
                       contain
                       eager
                       class="app-logo me-3"></v-img>

                <!--<v-slide-x-transition>
                <h3 class="app-title text--primary text-center">
                    S.A STOCK WILDLIFE
                </h3>
            </v-slide-x-transition>-->
            </router-link>
        </div>

        <!-- Navigation Items -->
        <v-list expand
                shaped
                class="vertical-nav-menu-items pr-5">
            <template v-for="(section, sectionIndex) in routes">
                <template v-if="!section.isAdminRequired || (section.isAdminRequired && user.isAdmin)">
                    <nav-menu-section-title :title="section.title" :key="`${sectionIndex}-menu-title`"></nav-menu-section-title>
                    <template v-for="(route, routeIndex) in section.routes">
                        <nav-menu-link v-if="!route.meta.hide && (route.meta.requiresAuth == (user.loggedIn) || route.meta.requiresAuth == null)"
                                       :title="route.meta.title"
                                       :to="route.path"
                                       :icon="route.meta.icon"
                                       :key="`${sectionIndex}-${routeIndex}-menu-link`">
                        </nav-menu-link>
                    </template>
                </template>
            </template>

            <!--Upload new terms navigation item-->
            <nav-menu-link v-if="user.isAdmin"
                           title="Upload new T&C's"
                           @click.native="openTermsDialog"
                           :icon="'mdi-file-upload'"
                           :key="`upload-terms-menu-link`">
            </nav-menu-link>

            <!--Subscribe navigation item-->
            <nav-menu-link title="Subscribe"
                           v-on:list-item-clicked="showSubscribe = true"
                           :icon="`mdi-plus`"
                           :key="`subscribe-menu-link`">
            </nav-menu-link>

            <!--Verify navigation item-->
            <nav-menu-link v-if="user.loggedIn && isVerified && !user.isAdmin"
                           title="Upload FICA Documents"
                           v-on:list-item-clicked="fica.show = true"
                           :icon="'mdi-paperclip'"
                           :key="'verify-menu-link'">
            </nav-menu-link>

        </v-list>

        <!--Subscribe dialog-->
        <v-dialog v-model="showSubscribe" max-width="400">
            <v-card>
                <v-card-title>
                    Subscribe <v-spacer></v-spacer>
                    <v-btn icon v-on:click="showSubscribe = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle>
                    Subscribe to our newsletter by completing the form below.
                </v-card-subtitle>
                <v-card-text>
                    <v-form>
                        <v-col cols="12">
                            <v-textField filled
                                         rounded
                                         v-model="firstName"
                                         label="First Name"
                                         placeholder="First Name"
                                         :rules="[rules.required]" />
                        </v-col>

                        <v-col cols="12">
                            <v-textField filled
                                         rounded
                                         v-model="lastName"
                                         label="Last Name"
                                         placeholder="Last Name"
                                         :rules="[rules.required]" />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field filled
                                          rounded
                                          v-model="email"
                                          label="Email"
                                          placeholder="john@example.com"
                                          :rules="[rules.required]" />
                        </v-col>

                        <v-col cols="12">
                            <v-textField filled
                                         rounded
                                         v-model="number"
                                         label="Mobile Number"
                                         placeholder="Number"
                                         :rules="[rules.required, rules.validMobileNumber]" />
                        </v-col>

                        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                            <span class="me-2">
                                Click to receive notifications via e-mail
                            </span>
                        </v-card-text>
                        <v-col cols="12">
                            <v-btn block
                                   color="primary"
                                   :loading="loading"
                                   :disabled="!canSignUp"
                                   v-on:click="subscribe">
                                Subscribe
                            </v-btn>
                        </v-col>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!--Upload FICA docs dialog-->
        <v-dialog v-model="fica.show" max-width="400" persistent :rules="[rules.isVerified]">
            <v-card>
                <v-card-title>
                    Upload FICA <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-file-input v-model="fica.files"
                                  color="primary accent-4"
                                  counter
                                  dense
                                  accept=".pdf"
                                  label="Upload FICA Documents"
                                  v-on:click:clear="removeFile"
                                  :show-size="1000"
                                  multiple>
                    </v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="danger"
                           text
                           small
                           v-on:click="fica.show = false">
                        No, Cancel
                    </v-btn>
                    <v-btn color="primary"
                           small
                           v-on:click="uploadFicaDocument"
                           :loading="fica.loading"
                           :disabled="!fica.files">
                        Upload
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--Upload new Terms dialog-->
        <v-dialog v-model="terms.show" max-width="400" persistent>
            <v-card>
                <v-card-title>
                    Upload Terms and Conditions <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-file-input v-model="terms.file"
                                  color="primary accent-4"
                                  counter
                                  dense
                                  accept=".pdf"
                                  label="Upload Terms and Conditions Document"
                                  v-on:click:clear="removeFile"
                                  :show-size="1000">
                    </v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="danger"
                           text
                           small
                           v-on:click="terms.show = false">
                        No, Cancel
                    </v-btn>
                    <v-btn color="primary"
                           small
                           v-on:click="uploadTermsDocument"
                           :loading="terms.loading"
                           :disabled="!terms.file">
                        Upload
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--<a href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template"
       target="_blank"
       rel="nofollow">
        <v-img :src="require(`@/assets/images/pro/upgrade-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
               alt="upgrade-banner"
               transition="scale-transition"
               class="upgrade-banner mx-auto"
               max-width="230"></v-img>
    </a>-->

    </v-navigation-drawer>
</template>

<script>
    import { routerOptions } from '../../router/routes.js'
    import NavMenuSectionTitle from './nav-menu-section-title.vue'
    import NavMenuLink from './nav-menu-link.vue'
    import { authStore } from '../../store/authStore'
    export default {
        name: 'SideMenu',
        components: {
            NavMenuSectionTitle,
            NavMenuLink
        },
        props: {
            isDrawerOpen: {
                type: Boolean,
                default: null,
            },
        },
        data: () => ({
            routes: routerOptions,
            user: authStore.data.user,
            showSubscribe: false,
            fica: { files: [], loading: false, show: false },
            terms: { files: [], loading: false, show: false },
            email: '',
            number: '',
            firstName: '',
            lastName: '',
            loading: false,
            rules: {
                required: value => !!value || 'Required.',
                isVerified: false,
                validMobileNumber: value => {
                    if (/^\d{10}/.test(value)) {
                        return true;
                    }
                    return 'Enter a valid number.';
                }
            }
        }),
        methods: {
            subscribe: async function () {
                this.loading = true;
                let model = {
                    email: this.email,
                    number: this.number,
                    firstName: this.firstName,
                    lastName: this.lastName
                };
                let result = await this.$axios.post(`/api/Subscribe/Subscribe`, model)
                alert(result.data.message)
                this.showSubscribe = false
                this.email = ''
                this.number = ''
                this.firstName = ''
                this.lastName = ''
                this.loading = false;
            },
            uploadFicaDocument: async function () {
                let form = new FormData();
                console.log(this.fica.files);
                for (let index = 0; index < this.fica.files.length; index++) {
                    let file = this.fica.files[index];
                    form.append('files', file);
                }
                console.log(form)
                let result = await this.$axios.post(`/api/UploadFICA/UploadFicaDocument`, form,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                if (result.status == 200) {
                    this.images = result.data
                }
                this.files = []

                if (result.status === 200) {
                    this.fica.files = [];
                    
                    //if (changeDocument) {
                    //    this.user.document = `${username}.pdf?rand=${Math.random()}`;
                    //}

                    this.user.documentLoading = false;
                    this.user.documentDialog = false;
                    return result.data;
                }
                return null;
            },
            openTermsDialog() {
                this.terms.show = true;
            },
            uploadTermsDocument: async function () {
                if (!this.terms.file) return;
                this.terms.loading = true;

                let form = new FormData();
                form.append('file', this.terms.file);

                let result = await this.$axios.post(`/api/UploadTerms`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (result.status === 200) {
                    this.terms.file = null;
                    this.terms.show = false;
                }
                this.terms.loading = false;
                return result.data;
            },
            removeFile() {
                this.uploadFICA.file = [];
                this.tc.file = [];
            },
        },
        computed: {
            isValidNumber: function () {
                const numberValidation = this.rules.validMobileNumber(this.cellphone.trim());
                if (numberValidation !== true) {
                    return false;
                }
                else {
                    return true;
                }
            },
            canSignUp: function () {
                if (this.firstName.trim() == '') {
                    return false;
                }
                if (this.lastName.trim() == '') {
                    return false;
                }
                if (!this.numberValidation && this.number.trim() === '') {
                    return false;
                }
                if (this.email.trim() == '') {
                    return false;
                }
                return true;
            },
            isVerified() {
                return this.user && this.user.isVerified;
            }
        }
    };
</script>