<template>
    <div>
        <!-- login form -->
        <v-card-text>
            <v-form>
                <v-text-field v-model="firstName"
                              filled
                              rounded
                              label="First Name"
                              placeholder="John"
                              :rules="[rules.required]"
                              class="mb-3"></v-text-field>

                <v-text-field v-model="lastName"
                              filled
                              rounded
                              label="Last Name"
                              placeholder="Doe"
                              :rules="[rules.required]"
                              class="mb-3"></v-text-field>

                <!--<v-select v-model="country"
                          variant="solo-filled"
                          label="Country"
                          :items="items"
                          density="comfortable"
                          filterable
                          :searchable="true"
                          :no-data-text="'No country found'"
                          :menu-props="{ maxHeight: '300px', offsetY: true, closeOnContentClick: false }"
                          autocomplete
                          :rules="[rules.required]"
                          class="mb-3"></v-select>-->

                <v-text-field v-model="email"
                              filled
                              rounded
                              label="Email"
                              placeholder="john@example.com"
                              :rules="[rules.required]"
                              class="mb-3"></v-text-field>

                <v-text-field v-if="isVisibleCountry"
                              v-model="cellphone"
                              filled
                              rounded
                              label="Cellphone"
                              placeholder="Mobile number (not +27 format)"
                              :rules="[rules.required, rules.validMobileNumber]"
                              class="mb-3"></v-text-field>

                <v-text-field v-model="password"
                              filled
                              rounded
                              :type="isPasswordVisible ? 'text' : 'password'"
                              label="Password"
                              placeholder="············"
                              :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                              :rules="[rules.required]"
                              @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>
                <div>
                    <v-row align="center" justify="center">
                        <v-checkbox v-model="allowRegistration" class="mt-4">
                            <template v-slot:label>
                                <div>
                                    Accept&nbsp;
                                </div>
                            </template>
                        </v-checkbox>
                        <a v-on:click.stop="openTermsAndConditions" class="v-label" style="margin-bottom: 2px">terms and conditions</a>
                    </v-row>

                    <v-btn block
                           color="primary"
                           :disabled="!canSignUp"
                           :loading="loading"
                           v-on:click="signUp">
                        Sign Up
                    </v-btn>
                </div>

            </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2">
                Already have an account?
            </span>
            <a text v-on:click="$emit('sign-in-clicked')">
                Sign in instead
            </a>
        </v-card-text>
    </div>
</template>

<style lang="scss">
    @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>

<script>
    export default {
        name: 'SignInScreen',
        components: {
        },
        data: () => ({
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            cellphone: '',
            country: 'South Africa',
            isPasswordVisible: false,
            loading: false,
            allowRegistration: false,
            visibleCountry: 'South Africa',
            rules: {
                required: value => !!value || 'Required.',
                validMobileNumber: value => {
                    if (/^\d{10}/.test(value)) {
                        return true;
                    }
                    return 'Enter a valid number.';
                }
            },
            items: ["Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda",
                "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas",
                "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin",
                "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei",
                "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
                "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia",
                "Comoros", "Congo, Democratic Republic of the", "Congo, Republic of the",
                "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czechia", "Denmark", "Djibouti",
                "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador",
                "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji",
                "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana",
                "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana",
                "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran",
                "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan",
                "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", "Latvia",
                "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
                "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta",
                "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia",
                "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique",
                "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
                "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia", "Norway",
                "Oman", "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea",
                "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania",
                "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia",
                "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe",
                "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore",
                "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
                "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname",
                "Sweden", "Switzerland", "Syria", "Tajikistan", "Tanzania", "Thailand",
                "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey",
                "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates",
                "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu",
                "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"]
        }),
        methods: {
            signUp: async function () {
                this.loading = true;

                let model = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    cellphone: this.cellphone,
                    password: this.password
                };

                if (!this.canSignUp) {
                    return;
                }

                let result = await this.$axios.post(`/api/Auth/SignUp`, model)
                if (result.status == 200) {
                    this.events = result.data
                    this.$emit('signed-in');
                    alert('Please follow the link in your email to verify your account')
                }
                else {
                    alert(this.result.data.message)
                }

                this.loading = false;
            },
            openTermsAndConditions() {
                const url = '/terms-and-conditions/TermsAndConditions.pdf';
                window.open(url, '_blank');
            }
        },
        computed: {
            isVisibleCountry: function () {
                return this.country === this.visibleCountry;
            },
            canSignUp: function () {
                if (this.allowRegistration) {
                    if (this.firstName.trim() == '') {
                        return false;
                    }
                    if (this.lastName.trim() == '') {
                        return false;
                    }
                    if (this.isVisibleCountry && this.cellphone.trim() === '') {
                        return false;
                    }
                    if (this.isVisibleCountry) {
                        const cellphoneValidation = this.rules.validMobileNumber(this.cellphone.trim());
                        if (cellphoneValidation !== true) {
                            return false;
                        }
                    }
                    if (this.email.trim() == '') {
                        return false;
                    }
                    if (this.password.trim() == '') {
                        return false;
                    }
                    return true;
                }
                return false;
            }
        }
    };
</script>