<template>
    <div>
        <v-menu offset-y
                left
                nudge-bottom="14"
                min-width="230"
                content-class="user-profile-menu-content">
            <template v-slot:activator="{ on, attrs }">
                <v-badge bottom
                         :color="isVerified ? 'success' : 'error'"
                         overlap
                         offset-x="12"
                         offset-y="12"
                         class="ms-4"
                         dot>
                    <v-avatar size="40px"
                              v-bind="attrs"
                              v-on="on"
                              color="primary">
                        <span class="white--text text-h5">{{user.firstName[0]}} {{user.lastName[0]}}</span>
                    </v-avatar>
                </v-badge>
            </template>
            <v-list>
                <div class="pb-3 pt-2">
                    <div class="d-inline-flex flex-column justify-center ms-3"
                         style="vertical-align:middle">
                        <span class="text--primary font-weight-semibold mb-n1">
                            {{user.firstName}} {{user.lastName}}
                        </span>
                        <small class="text--disabled text-capitalize">{{user.email}}</small>
                    </div>
                </div>

                <v-divider></v-divider>

                <!-- Logout -->
                <v-list-item v-on:click="$emit('sign-out')">
                    <v-list-item-icon class="me-2">
                        <v-icon size="22">
                            mdi-logout-variant
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-on:click="showDeleteConfirm = true">
                    <v-list-item-icon class="me-2">
                        <v-icon size="22">
                            mdi-delete-variant
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Delete Account</v-list-item-title>
                </v-list-item>
            </v-list>

        </v-menu>
        <v-dialog v-model="showDeleteConfirm" max-width="400" persistent>
            <v-card>
                <v-card-title>
                    Delete account <v-spacer></v-spacer>
                    <v-btn icon v-on:click="showDeleteConfirm = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                    <span class="me-2">
                        Are you sure you want to delete your account? this will permanently delete your account.
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="success" outlined v-on:click="showDeleteConfirm = false">No, Cancel</v-btn>
                    <v-btn color="error" v-on:click="confirmDelete">Yes, delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: 'AppBarUserMenu',
        components: {
        },
        props: {
            user: {
                type: Object,
                default: null,
            },
        },
        data: () => ({
            showDeleteConfirm: false,
            rules: {
                isVerified: false,
            },
        }),
        computed: {
            isVerified() {
                return this.user && this.user.isVerified;
            }
        },
        methods: {
            confirmDelete: async function () {
                let result = await this.$axios.post(`/api/Admin/DeleteUser`)
                if (result.status == 200) {
                    alert(result.data.message)
                    this.showDeleteConfirm = false;
                    if (result.data.message.indexOf('admin account') <= -1) {
                        this.$emit('sign-out')
                    }
                }
                else {
                    alert('Something went wrong, please contact us using the contact form.')
                }
            }
        }
    };
</script>
<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
