<template>
    <v-app>
        <side-menu :is-drawer-open.sync="isDrawerOpen"></side-menu>

        <v-app-bar app flat absolute color="transparent">
            <div class="boxed-container w-full">
                <div class="d-flex align-center mx-6">
                    <!-- Left Content -->
                    <v-app-bar-nav-icon class="d-block d-lg-none me-2"
                                        @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
                    <v-spacer></v-spacer>

                    <!--<theme-switcher></theme-switcher>-->

                    <template v-if="user.loggedIn">
                        <app-bar-user-menu :user="user" v-on:sign-out="signOut"></app-bar-user-menu>
                    </template>
                    <template v-else>
                        <v-btn text color="primary" class="ms-2" small v-on:click="auth.dialog = true;">
                            Sign In
                        </v-btn>
                    </template>
                </div>
            </div>
        </v-app-bar>

        <v-main>
            <!--<v-btn color="#25D366"
                   elevation="24"
                   absolute
                   right
                   dark
                   style="bottom: 20px"
                   href="https://wa.me/+27738151335?text=Hi I am contacting in regards to SA Gun Online Auctions."
                   target="_blank"
                   class="font-weight-black"
                   rounded>
                Whatsapp
                <v-icon class="ml-2">mdi-whatsapp</v-icon>
            </v-btn>-->
            <v-container>
                <router-view></router-view>
            </v-container>
        </v-main>
        <v-dialog v-model="auth.dialog" max-width="320">
            <v-card>
                <!-- logo -->
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-img v-if="$vuetify.theme.dark"
                           :src="require('@/assets/images/logos/logo.png')"
                           max-height="120px"
                           max-width="120px"
                           alt="logo"
                           contain
                           eager
                           class="app-logo me-3"></v-img>
                    <v-img v-else
                           :src="require('@/assets/images/logos/logo.png')"
                           max-height="120px"
                           max-width="120px"
                           alt="logo"
                           contain
                           eager
                           class="app-logo me-3"></v-img>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-window v-model="auth.screen" reverse touchless>
                    <v-window-item>
                        <sign-in-screen v-on:sign-up-clicked="auth.screen = 1" v-on:signed-in="checkUser" v-on:forgot-password-clicked="auth.screen = 2" v-on:terms-and-conditions-screen-clicked="openTermsAndConditions"></sign-in-screen>
                    </v-window-item>
                    <v-window-item>
                        <sign-up-screen v-on:sign-in-clicked="auth.screen = 0" v-on:signed-in="checkUser" v-on:terms-and-conditions-screen-clicked="openTermsAndConditions"></sign-up-screen>
                    </v-window-item>
                    <v-window-item>
                        <forgot-password-screen v-on:sign-in-clicked="auth.screen = 0"></forgot-password-screen>
                    </v-window-item>
                    <v-window-item>
                        <reset-password-screen v-on:sign-in-clicked="auth.screen = 0"></reset-password-screen>
                    </v-window-item>
                    <v-window-item>
                        <terms-and-conditions-screen v-on:sign-up-clicked="auth.screen = 1" v-on:sign-in-clicked="auth.screen = 0"></terms-and-conditions-screen>
                    </v-window-item>
                    <v-window-item>
                        <confirm-email-screen></confirm-email-screen>
                    </v-window-item>
                </v-window>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import SideMenu from './components/layout/side-menu.vue'
    //import ThemeSwitcher from './components/layout/theme-switcher.vue'
    import AppBarUserMenu from './components/layout/app-bar-user-menu.vue'
    import SignInScreen from './views/auth/signin-screen.vue'
    import SignUpScreen from './views/auth/signup-screen.vue'
    import ForgotPasswordScreen from './views/auth/forgot-password-screen.vue'
    import ResetPasswordScreen from './views/auth/reset-password-screen.vue'
    /*import TermsAndConditionsScreen from './views/auth/terms-and-conditions-screen.vue'*/
    import ConfirmEmailScreen from './views/auth/confirm-email.vue'
    import { authStore, authStoreMutations } from './store/authStore'
    import { hubStoreMutations } from './store/hubStore'
    import router from './router/router'
    export default {
        name: 'App',
        components: {
            SideMenu,
            //ThemeSwitcher,
            AppBarUserMenu,
            SignInScreen,
            SignUpScreen,
            ForgotPasswordScreen,
            ResetPasswordScreen,
            ConfirmEmailScreen,
            //TermsAndConditionsScreen
        },
        data: () => ({
            user: authStore.data.user,
            auth: authStore.data.auth,
            isDrawerOpen: true,
            hasOpened: false
        }),
        methods: {
            checkUser: async function () {
                await authStoreMutations.checkUser();
            },
            signOut: async function () {
                await authStoreMutations.signOut();
                //router.push({ path: '/' });
                alert('Signed out')
                router.push({ path: '/' });
            },
            setupHub: async function () {
                hubStoreMutations.setupHub();
            },
            openTermsAndConditions() {
                const url = '/terms-and-conditions/TermsAndConditions.pdf';
                window.open(url, '_blank');
            }
        },
        updated: function () {
            console.log(this.$route.path)
            if (this.$route.path == '/reset-password' && !this.hasOpened) {
                this.hasOpened = true;
                this.auth.screen = 3;
                this.auth.dialog = true;
            }
            if (this.$route.path == '/confirm-mail' && !this.hasOpened) {
                this.hasOpened = true;
                this.auth.screen = 5;
                this.auth.dialog = true;
            }
        },
        mounted: function () {
            this.checkUser();
            setTimeout(function () {
                this.isDrawerOpen = true;
            }.bind(this), 250)
        },
        created: function () {
            //this.setupHub();
        }
    };
</script>
