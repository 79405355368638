<template>
    <div class="my-2">

        <!-- login form -->
        <v-card-text>
            <v-form>
                <v-text-field v-model="email"
                              filled
                              rounded
                              hide-details
                              label="Email"
                              placeholder="john@example.com"
                              class="mb-3"></v-text-field>

                <v-text-field v-model="password"
                              filled
                              rounded
                              :type="isPasswordVisible ? 'text' : 'password'"
                              label="Password"
                              placeholder="············"
                              :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                              hide-details
                              @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>

                <v-row align="center" justify="center">
                    <v-checkbox v-model="allowLogin" class="mt-4">
                        <template v-slot:label>
                            <div>
                                Accept&nbsp;
                            </div>
                        </template>
                    </v-checkbox>
                    <a v-on:click="openTermsAndConditions" class="v-label" style="margin-bottom: 2px">terms and conditions</a>
                </v-row>

                <v-card-text class="d-flex align-center justify-center flex-wrap mt-3">
                    <span class="me-2">
                        Forgot your password?
                    </span>
                    <a text v-on:click="$emit('forgot-password-clicked')">
                        Reset Here
                    </a>
                </v-card-text>
                <v-btn block
                       color="primary"
                       class="mt-2"
                       :disabled="!canLogin"
                       :loading="loading"
                       v-on:click="signIn">
                    Login
                </v-btn>
            </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2">
                New on our platform?
            </span>
            <a text v-on:click="$emit('sign-up-clicked')">
                Create an account
            </a>
        </v-card-text>

    </div>
</template>
<style lang="scss">
    @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>

<script>
    export default {
        name: 'SignInScreen',
        components: {
        },
        data: () => ({
            email: '',
            password: '',
            isPasswordVisible: false,
            allowLogin: false,
            loading: false
        }),
        methods: {
            signIn: async function () {
                this.loading = true;

                let model = {
                    email: this.email,
                    password: this.password
                };

                let result = await this.$axios.post(`/api/Auth/SignIn`, model)

                if (result.status == 200 && result.data.success) {
                    this.events = result.data
                    this.$emit('signed-in');
                    alert('Signed in')
                }
                else {
                    alert(result.data.message)
                }

                this.loading = false;
            },
            openTermsAndConditions() {
                const url = '/terms-and-conditions/TermsAndConditions.pdf';
                window.open(url, '_blank');
            }
        },
        computed: {
            canLogin: function () {
                if (this.allowLogin) {
                    if (this.email.trim() == '') {
                        return false;
                    }
                    if (this.password.trim() == '') {
                        return false;
                    }
                    return true;
                }
                else {
                    return false;
                }
            }
        }
    };
</script>
